<template>
  <div class="wel-wrap">
    <div class="wel-main">
      <img src="../../assets/img/logo.png" alt="" />
      <span class="logo">LOGO</span>
    </div>
    <div class="wel-foot">
      <span>welcome to</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'welcome',

  data() {
    return {
      times: 5
    }
  },
  created() {
    this.getTime()
  },
  methods: {
    getTime() {
      this.timer = setInterval(() => {
        this.times--
        if (this.times === 0) {
          this.$router.push({ name: 'login' })
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.wel-wrap {
  min-height: 100vh;
  background-color: #131313;
}
.wel-main {
  height: 50vh;
  background: url(../../assets/img/启动页.png) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    margin-top: 38px;
    font-size: 28px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #ffffff;
  }
}

.wel-foot {
  //   color: #fff;
  text-align: center;
  width: 80%;
  position: absolute;
  bottom: 90px;
  left: 50%;
  margin-left: -40%;
  font-size: 28px;
  font-family: PingFang SC-常规体, PingFang SC;
  font-weight: normal;
  color: #888888;
}
</style>
