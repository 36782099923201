<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('退出成功') }}</div>
      <div></div>
    </div>
    <div class="content">
      <img class="log" src="../../assets/img/passsucc.png" alt="" />
     
      <div class="koxinga">{{ $t('重新登录') }}</div>
    </div>

    <div style="margin: 16px; padding: 32px 60px 0">
      <van-button class="btn" round block type="info" @click="onSubmit">{{
        $t('重新登录')
      }}</van-button>
    </div>
  </div>
</template>

<script>
	import {
	  apiLoginout,
	  
	} from '@/http/api/'
export default {
  name: 'welcome',

  data() {
    return {}
  },
  created() {
  	apiLoginout({}).then((res) => {
  	  console.log(res)
  	  localStorage.removeItem('key')
  	  setTimeout(() => {
  	    this.$router.replace('/login')
  	  }, 1000)
  	 
  	})
  },
  methods: {
    getBack() {
      this.$router.back()
    },
    onSubmit() {
     this.$router.replace('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .content {
    text-align: center;
    margin-top: 206px;
    .log {
      width: 170px;
      //   height: 170px;
    }
    .forget {
      font-size: 36px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #fff;
      margin-top: 70px;
      margin-bottom: 30px;
    }
    .koxinga {
      font-size: 28px;
      color: #888888;
    }
  }
  .btn {
    box-shadow: 0px 10px 20px #0d9135;
    font-size: 30px;
    height: 103px;
    background-color: #0d9135;
    border: none;
    color: #000;
  }
}
</style>
