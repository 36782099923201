import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home'
import my from '@/views/my'
import login from '@/views/login'
import start from '@/views/start'
import findSuccess from '@/views/findSuccess'
import forgetpass from '@/views/forgetpass'
import register from '@/views/register'
import findFailure from '@/views/findFailure'
import market from '@/views/market'
import team from '@/views/team'
import teamRules from '@/views/teamRules'
import drawings from '@/views/drawings'
import serveWay from '@/views/serveWay'
import actionCenter from '@/views/actionCenter'
import myRobot from '@/views/myRobot'
import recharge from '@/views/recharge'
import teamIncome from '@/views/teamIncome'
import personalIncome from '@/views/personalIncome'
import payment from '@/views/payment'
import robot from '@/views/robot'
import payMethods from '@/views/payMethods'
import setPwd from '@/views/setPwd'
import task from '@/views/task'
import leaveMessage from '@/views/leaveMessage'
import bankCard from '@/views/bankCard'
import bindingPurse from '@/views/bindingPurse'
import connectDebitCard from '@/views/connectDebitCard'
import helpCenter from '@/views/helpCenter'
import messageDetails from '@/views/messageDetails'
import joinUs from '@/views/joinUs'
import address from '@/views/address'
import bankset from '@/views/bankset'
import paybank from '@/views/paybank'
import payusdt from '@/views/payusdt'
import teaminfo from '@/views/teaminfo'
import lang from '@/views/lang'
import huodong from '@/views/huodong'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/my',
    name: 'my',
    component: my
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/start',
    name: 'start',
    component: start
  },
  {
    path: '/findSuccess',
    name: 'findSuccess',
    component: findSuccess
  },
  {
    path: '/forgetpass',
    name: 'forgetpass',
    component: forgetpass
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/findFailure',
    name: 'findFailure',
    component: findFailure
  },
  {
    path: '/market',
    name: 'market',
    component: market
  },
  {
    path: '/team',
    name: 'team',
    component: team
  },
  {
    path: '/teamRules',
    name: 'teamRules',
    component: teamRules
  },
  {
    path: '/drawings',
    name: 'drawings',
    component: drawings
  },
  {
    path: '/serveWay',
    name: 'serveWay',
    component: serveWay
  },
  {
    path: '/actionCenter',
    name: 'actionCenter',
    component: actionCenter
  },
  {
    path: '/myRobot',
    name: 'myRobot',
    component: myRobot
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: recharge
  },
  {
    path: '/teamIncome',
    name: 'teamIncome',
    component: teamIncome
  },
  {
    path: '/personalIncome',
    name: 'personalIncome',
    component: personalIncome
  },
  {
    path: '/payment',
    name: 'payment',
    component: payment
  },
  {
    path: '/robot',
    name: 'robot',
    component: robot
  },
  {
    path: '/payMethods',
    name: 'payMethods',
    component: payMethods
  },
  {
    path: '/setPwd',
    name: 'setPwd',
    component: setPwd
  },
  {
    path: '/task',
    name: 'task',
    component: task
  },
  {
    path: '/leaveMessage',
    name: 'leaveMessage',
    component: leaveMessage
  },
  {
    path: '/bankCard',
    name: 'bankCard',
    component: bankCard
  },
  {
    path: '/bindingPurse',
    name: 'bindingPurse',
    component: bindingPurse
  },
  {
    path: '/connectDebitCard',
    name: 'connectDebitCard',
    component: connectDebitCard
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: helpCenter
  },
  {
    path: '/messageDetails',
    name: 'messageDetails',
    component: messageDetails
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: joinUs
  },
  {
    path: '/address',
    name: 'address',
    component: address
  },
	{
	  path: '/bankset',
	  name: 'bankset',
	  component: bankset
	},
	{
	  path: '/paybank',
	  name: 'paybank',
	  component: paybank
	},
	{
	  path: '/payusdt',
	  name: 'payusdt',
	  component: payusdt
	},
	{
	  path: '/teaminfo',
	  name: 'teaminfo',
	  component: teaminfo
	},
	{
	  path: '/lang',
	  name: 'lang',
	  component: lang
	},
	{
	  path: '/huodong',
	  name: 'huodong',
	  component: huodong
	},
]

const router = new VueRouter({
  routes
})

export default router
