<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('我的机器人') }}</div>
      <div></div>
    </div>
    <div class="choose">
		
      <van-tabs v-model="active">
        <van-tab :title="$t('未激活')" name="1">
          <div v-if="info.length > 0">
            <div class="card" v-for="item in info" :key="item">
              <div class="hea">
                <div>
                  <img class="img" src="../../assets/img/info.png" alt="" />
                </div>
                <div class="middle">
                  <span>{{item.name}}</span>
                </div>
                <div>
                  <div class="btn" @click="jihuoai(item.id)">{{ $t('激活') }}</div>
                  
                </div>
				
              </div>
              <div class="info">
                <div class="natixis">
                  <div class="spec">{{ $t('来源') }} :</div>
                  <div> {{ $t(item.source) }}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('价格') }} :</div>
                  <div>{{item.price}}USDT</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('启动押金') }} :</div>
                  <div>{{item.dan_price}}USDT</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('有效期') }} :</div>
                  <div>{{item.gq_day}}{{ $t('天') }}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('唯一码') }} :</div>
                  <div>{{item.code}}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('购买时间') }} :</div>
                  <div>{{item.add_time}}</div>
                </div>
				 <div class="natixis hea" @click="zengsong(item)" v-if="item.is_seller==0 ">
					<div class="suspend">{{ $t('赠送') }}</div> 
				 </div>
				 <div class="natixis hea" v-if="item.is_seller==1">
				 					<div style="color: #800000;">{{ $t('出售中') }}</div> 
				 </div>
              </div>
            </div>
          </div>
          <div class="pitera" v-else>
            <img src="../../assets/img/info.png" alt="" />
            <div>{{$t('暂无数据')}}</div>
          </div>
        </van-tab>
        <van-tab :title="$t('工作中')" name="2">
          <div v-if="info2.length > 0">
            <div class="card" v-for="item in info2" :key="item">
              <div class="hea">
                <div>
                  <img class="img" src="../../assets/img/info.png" alt="" />
                </div>
                <div class="middle">
                  <span>{{item.name}}</span>
                </div>
                <!--<div>
                  <!--<div class="btn">{{ $t('激活') }}</div> -->
                  <!--<div class="suspend"  @click="apistop(item.id)">{{ $t('暂停') }}</div> 
                </div> -->
              </div>
              <div class="info">
                <div class="natixis">
                  <div class="spec">{{ $t('来源') }} :</div>
                  <div> {{ $t(item.source) }}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('价格') }} :</div>
                  <div>{{item.price}}USDT</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('启动押金') }} :</div>
                  <div>{{item.dan_price}}USDT</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('有效期') }} :</div>
                  <div>{{item.gq_day}}{{ $t('天') }}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('唯一码') }} :</div>
                  <div>{{item.code}}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('购买时间') }} :</div>
                  <div>{{item.add_time}}</div>
                </div>
				
				<div class="natixis">
				  <div class="spec">{{ $t('激活时间') }} :</div>
				  <div>{{item.act_time}}</div>
				</div>
				<!-- <div class="natixis">
				  <div class="spec">{{ $t('运行时长') }} :</div>
				  <div>{{item.now_time}}</div>
				</div> -->
              </div>
            </div>
          </div>
          <div class="pitera" v-else>
            <img src="../../assets/img/info.png" alt="" />
            <div>{{$t('暂无数据')}}</div>
          </div>
        </van-tab>
       <!-- <van-tab :title="$t('暂停')" name="3">
          <div v-if="info3.length > 0">
            <div class="card" v-for="item in info3" :key="item">
              <div class="hea">
                <div>
                  <img class="img" src="../../assets/img/info.png" alt="" />
                </div>
                <div class="middle">
                  <span>{{item.name}}</span>
                </div>
                <div>
                  <div class="btn">{{ $t('激活') }}</div>
                  <div class="btn" @click="jihuoai(item.id)">{{ $t('激活') }}</div>
                </div>
              </div>
              <div class="info">
                <div class="natixis">
                  <div class="spec">{{ $t('来源') }} :</div>
                  <div> {{ $t(item.source) }}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('价格') }} :</div>
                  <div>{{item.price}}USDT</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('启动押金') }} :</div>
                  <div>{{item.dan_price}}USDT</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('有效期') }} :</div>
                  <div>{{item.gq_day}}{{ $t('天') }}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('唯一码') }} :</div>
                  <div>{{item.code}}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('购买时间') }} :</div>
                  <div>{{item.add_time}}</div>
                </div>
				
				<div class="natixis">
				  <div class="spec">{{ $t('激活时间') }} :</div>
				  <div>{{item.act_time}}</div>
				</div>
				
              </div>
            </div>
          </div>
          <div class="pitera" v-else>
            <img src="../../assets/img/info.png" alt="" />
            <div>{{$t('暂无数据')}}</div>
          </div>
          </van-tab> -->
        <van-tab :title="$t('结束')" name="4">
          <div v-if="info4.length > 0">
            <div class="card" v-for="item in info4" :key="item">
              <div class="hea">
                <div>
                  <img class="img" src="../../assets/img/info.png" alt="" />
                </div>
                <div class="middle">
                  <span>{{item.name}}</span>
                </div>
                <!--<div>
                  <div class="btn">{{ $t('激活') }}</div>
                 <div class="btn" @click="jihuoai(item.id)">{{ $t('激活') }}</div>
                </div>-->
              </div>
              <div class="info">
                <div class="natixis">
                  <div class="spec">{{ $t('来源') }} :</div>
                  <div> {{ $t(item.source) }}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('价格') }} :</div>
                  <div>{{item.price}}USDT</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('启动押金') }} :</div>
                  <div>{{item.dan_price}}USDT</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('有效期') }} :</div>
                  <div>{{item.gq_day}}{{ $t('天') }}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('唯一码') }} :</div>
                  <div>{{item.code}}</div>
                </div>
                <div class="natixis">
                  <div class="spec">{{ $t('购买时间') }} :</div>
                  <div>{{item.add_time}}</div>
                </div>
          				
          				<div class="natixis">
          				  <div class="spec">{{ $t('激活时间') }} :</div>
          				  <div>{{item.act_time}}</div>
          				</div>
          				
              </div>
            </div>
          </div>
          <div class="pitera" v-else>
            <img src="../../assets/img/info.png" alt="" />
            <div>{{$t('暂无数据')}}</div>
          </div>
		  </van-tab> 
      </van-tabs>
    </div>
	<!-- <van-popup v-model="paypwd" round closeable @close="closepaypwd" class="toastIndex">
		  <div class="wt-select-dialog" style=" height: 8.00097rem;text-align: center;background-color: #2a2c2e">
		    <br>
		    <div class="wt-dialog_bd">
		
			<p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">{{$t("请输入交易密码")}} 
			<span style="color: #26B77E;"></span></p>
		     <div class="wt-card" style="min-height: 50px;text-align: center;">
		         <van-field class="input" v-model="paypasswd" :placeholder="$t('请输入交易密码')" />
		      </div>
		      <van-row >
		
				<div @click="zengsong">
		        <van-col span="12" style="width: 90%; padding-top: 20px;">
		          <van-button  type="info" style="width: 5.5rem;background-color: #2a2c2e" >{{
		            $t("确定")
		          }}</van-button>
		        </van-col>
				</div>
		      </van-row>
		    </div>
		  </div>
		</van-popup> -->
	<popup ref="popup">
	  <div class="popuptip" style="color: #fff">
	    <div class="flex_row_space-between f32">
	      <div class="ml32">{{ $t('赠送') }}</div>
	      <div class="mr32 flexbox" @click="$refs.popup.hide()">X</div>
	    </div>

	
	    <div class="flex_row_space-between byrobot f28 mt50 ml32 mr32">
	      <div class="ml32 flexbox hcenter bcolor">
	        <img class="robotIcon" src="@/assets/icon/robot.png" alt="" />
	        <span class="ml20">{{ $t('机器人') }}</span>
	      </div>
	      <div class="flexbox hcenter mr32 bcolor">
	        <span class="f32">  {{ sale.name }}</span>
	        <span class="f18"></span>
	      </div>
	    </div>
	    <div class="ml32 mt50">
	      <span class="f32">{{ sale.price }}</span>
	      <span>USDT</span>
	     
	    </div>
	    <div class="ml32 mt20">
	      <span>{{ $t('唯一码') }} ：</span>
	      <span>{{ sale.code }} </span>
	    </div>
	  
	    <div class="ml32 mt20">
	      <span>{{ $t('有效期') }} ：</span>
	      <span>{{ sale.gq_day }} {{ $t('天') }}</span>
	    </div>
		<div class="ml32 mt20">
		         
		            <div class="rob-item" style="">
		              <select
		                v-model="type"
		                style="
		                  height: 50px;
		                  width: 95%;
		                  background-color: #3c434e;
		                  color: #ffffff;
		                  border: 5px;
		                "
		              >
		                <option value="1">{{ $t("赠送") }}</option>
		               <!-- <option value="2">{{ $t("出售") }}</option> -->
		              </select>
		            </div>
		         
		        </div>
				<div class="ml32 mr32 buy mt30 flex_row_space-between" v-show="chushouf">
				  <input
				    class="flex1 f32 ml20"
				    type="number"
				    style="color: #0d9135"
				    :placeholder="$t('请输入出售金额')"
				    v-model="amount"	
					
				  />
				</div>
	    <div class="ml32 mr32 buy mt30 flex_row_space-between">
	      <input
	        class="flex1 f32 ml20"
	        type="text"
	        style="color: #0d9135"
	        :placeholder="$t('请输入用户ID')"
	        v-model="userid"	
			
	      />
	    </div>
		<div class="ml32 mr32 buy mt30 flex_row_space-between">
		  <input
		    class="flex1 f32 ml20"
		    type="password"
		    style="color: #0d9135"
		    :placeholder="$t('请输入交易密码')"
		    v-model="paypasswd"	
			
		  />
		</div>
	    <div class="line ml32 mr32 mt50"></div>
	    <div class="ml32 ccolor">
	      <p>{{ $t('输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。') }}</p>
	     
	    
	    </div>
	    <div class="mt50" >
	      <hbbtn class="ml32" @clickEvent="okzengsong()">{{ $t('确认') }}</hbbtn>
	    </div>
	  </div>
	</popup>
	
    <ROBOT></ROBOT>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import popup from '@/components/popup'
import md5 from 'js-md5';
import hbbtn from '@/components/hb-btn'
import {
  NavBar,
  Button,
  DropdownMenu,
  DropdownItem,
  Tab,
  Tabs,
  Popup,
  Field,
  Slider,
  Toast,
} from "vant";

import {
  apimyrobots,
  apiactrobots,
  apigiverobots,
  apistop,
  apisellerrobots,
  apichushou,
  apiexperience_act
} from "@/http/api/";

export default {
  name: 'welcome',
  components: {
    ROBOT,
	popup,
	hbbtn,
	[Button.name]: Button,
	[DropdownMenu.name]: DropdownMenu,
	[DropdownItem.name]: DropdownItem,
	[Tab.name]: Tab,
	[Tabs.name]: Tabs,
	[Popup.name]: Popup,
	[Field.name]: Field,
	[Slider.name]: Slider,
  },
 
  data() {
    return {
      active: '1',
      list: [],
	  info:[], // 未激活 3
	  info2:[],// 工作中  2
	  info3:[],// 暂停中 4
	  info4:[],// 完成 1
	  token:"",
	  ticker:"",
	  sale:[],
	  kjid:"",
	  amount:0,
	  type: 1,
	 
	  chushouf: false,
	  paypwd:0,
	  paypasswd:'',
	  userid:""
    }
  },
  watch:{
	  type(old, val) {
	        if (old == 2) {
	          this.chushouf = true;
	        } else {
	          this.chushouf = false;
	        }
	      },
  },
 created() {
    var token = localStorage.getItem("key");
    console.log("token", token);
    if (!token) {
      this.$router.replace("/login");
    }
    this.token = token;
    this.init();
  },
  methods: {
	 
	  okzengsong() {
			
			
	        if (this.userid == "") {
	          Toast.fail({
			  message: this.$t('不能为空'),
			  className: 'toastIndex'
			  });
	          return;
	        }
			
	        if (this.kjid == "") {
	          Toast.fail({
	          message: this.$t('不能为空'),
	          className: 'toastIndex'
	          });
	          return;
	        }
			
	  	  if (this.paypasswd == "") {
	  	    Toast.fail({
	  	    message: this.$t('不能为空'),
	  	    className: 'toastIndex'
	  	    });
	  	    return;
	  	  }
		  
	        if (this.type == 1) {
	          Toast.loading({
	            duration: 0,
	            message: this.$t('加载中'),
	            forbidClick: true,
				className: 'toastIndex'
	          });
	  	
	  			apisellerrobots({
	  			  userid: this.token,
	  			  kjid: this.kjid,
	  			  mobile: this.userid,
	  			  amount: 0,
	  			  paypasswd:md5(this.paypasswd),
	  			}).then((res) => {		
	  			  Toast.clear();
	  			  if (res.status == 1) {
	  				setTimeout(() => {
	  				 
					  Toast.success({
						  message:this.$t(res.message),
						  className: 'toastIndex'
					  })
					  
	  				  location.reload();
	  				}, 1000);
	  				this.show = false;
	  			  } else {
	  				Toast.fail({
						message:this.$t(res.message),
						 className: 'toastIndex'
					});
	  			  }
	  			});
	  		
	        } else if (this.type == 2) {
				if(this.kjid == 22 || this.kjid == 23){
					Toast.fail({
					message: this.$t('不支持出售'),
					className: 'toastIndex'
					});
					return;
				}
	          Toast.loading({
	            duration: 0,
	            message: this.$t('加载中'),
	            forbidClick: true,
	          });
	  			apisellerrobots({
	  			  userid: this.token,
	  			  kjid: this.kjid,
	  			  mobile: this.userid,
	  			  amount: this.amount,
	  			  paypasswd:md5(this.paypasswd),
	  			}).then((res) => {		  
	  			  if (res.status == 1) {
	  				Toast.clear();
	  				setTimeout(() => {
	  				  Toast.success(this.$t(res.message));
	  				  this.init();
	  				}, 1000);
	  				this.show = false;
	  			  } else {
	  				Toast.fail(this.$t(res.message));
	  			  }
	  			});	
	        }
	      },
	zengsong1()
	{
		
		this.paypwd=1;
	},
	closepaypwd()
		{
			this.paypwd=0;
			
	
		},
	zengsong(info){
		this.sale = info;
		this.kjid = info.id;
		
		this.amount = info.price;
		this.$refs.popup.show()
	},
    getBack() {
      this.$router.back()
    },
	 apistop(kjid) {
		 
		 Toast.fail(this.$t("0$ el bot activado no puede ser pausado"));
		 return;
	      apistop({
	        userid: this.token,
	        kjid: kjid,
	      }).then((res) => {
	        console.log(res);
			 if (res.status == 1) {
				 setTimeout(() => {
				   Toast.success(this.$t(res.message));
				   this.active = '3';
				   
				   this.init();
				 }, 500);
			 }else{
				 Toast.fail(this.$t(res.message));
			 }
	        
	      });
	    },
	 jihuoai(id) {
		 this.active = '2';
	      if (id == "") {
	        Toast.fail(this.$t("不能为空"));
	        return;
	      } 
		  Toast.loading({
		    duration: 0,
		    message: this.$t('加载中'),
		    forbidClick: true,
		  });
		  apiactrobots({
				userid: this.token,
				kjid: id,
			  }).then((res) => {
				Toast.clear();
				if (res.status == 1) {
				  setTimeout(() => {
					Toast.success(this.$t(res.message));
					this.init();
				  }, 1000);
				} else {
				  Toast.fail(this.$t(res.message));
				}
		});
	},
	init(){
		Toast.loading({
		  duration: 0,
		  message: this.$t('加载中'),
		  forbidClick: true,
		});
		this.info = []
		this.info2 = []
		this.info3 = []
		this.info4 = []
		var that = this;
		apimyrobots({
		  userid: this.token,
		}).then((res) => {
		  console.log(res);
		  Toast.clear();
		  var arr = res.info;
		  arr.forEach(function(v){ 
		    if(v.status == 1) { // 已结束
			  that.info4.push(v);
			}else if(v.status == 2){ // 工作中
				that.info2.push(v);
			}else if(v.status == 3){ // 未激活
				that.info.push(v);
			}else if(v.status == 4){ // 暂停中
				that.info3.push(v);
			}
		  })
		  if (this.ticker) { //这一段是防止进入页面出去后再进来计时器重复启动
				clearInterval(this.ticker);
		   }
		   console.log(that.info2);
		   this.beginTimer(); //启动计时器减指定字段的时间	
		});
	},
	 time(time) { //这个函数是每秒把时间重新计算下
		        if (time >= 0) {
		          let d = Math.floor(time  / 60 / 60 / 24);
		          let h = Math.floor((time  / 60 / 60) % 24);
		          let m = Math.floor((time  / 60) % 60);
		          let s = Math.floor((time) % 60);
		           return d+':'+h+':'+m+':'+s;
				  }
	 },
	  beginTimer(){
		 this.ticker = setInterval(() => {
			for (let i = 0, len = this.info.length; i < len; i++) {
			  const item = this.info[i];
			  if (item.countdown > 0) {
				item.countdown = item.countdown - 1;
			  }
			}
		}, 1000);
	},
  }
}
</script>

<style lang="scss" scoped>
.page {
	.van-tabs__nav{
		 background: rgb(77, 77, 77);
	}
  padding: 0 30px;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .popuptip {
    padding: 40px 0;
    height: 1166px;
    background: #2a2c2e;
    border-radius: 50px 50px 0px 0px;
    .byrobot {
      height: 130px;
      background: #0d9135;
      border-radius: 27px 27px 27px 27px;
    }
    .buy {
      height: 107px;
      background: #1b1c1d;
      border-radius: 19px 19px 19px 19px;
      opacity: 1;
      border: 1px solid rgba(243, 204, 46, 0.20000000298023224);
    }
  }
  .robot {
    height: 400px;
    background: #2c2e30;
    border-radius: 28px;
    .input {
      height: 89px;
      background: #171718;
      border-radius: 45px;
    }
    .btn {
      width: 165px;
      height: 56px;
      background: #0d9135;
      box-shadow: 0px 8px 30px 1px rgba(243, 204, 46, 0.30000001192092896);
      border-radius: 28px 28px 28px 28px;
    }
  }
  .choose {
    margin-top: 60px;
	
    .card {
      color: #fff;
      padding: 24px 46px;
      border-radius: 20px;
      margin-bottom: 30px;
	  
      background-color: #2a2c2e;
      .hea {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #565353;
        justify-content: space-between;
        .img {
          width: 100px;
          height: 100px;
        }
        .middle {
          flex: 1;
          margin-left: 20px;
        }
        .btn {
          color: #fff;
          padding: 12px 30px;
          border-radius: 50px;
          background-color: #78a818;
        }
        .suspend {
          color: #fff;
          padding: 12px 30px;
          border-radius: 50px;
          background-color: #0d9135;
        }
      }
      .info {
        margin-top: 20px;
        // display: flex;
        .natixis {
          margin-top: 30px;
          display: flex;
          .spec {
            color: #888;
            min-width: 100px;
            margin-right: 30px;
          }
        }
      }
    }
  }
  .pitera {
    color: #fff;
    line-height: 60px;
    font-size: 24px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
</style>
