<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('忘记密码') }}</div>
      <div></div>
    </div>
		<br>
		<br>
    <div class="content">
      <van-form>
        <div class="phone">
         
          <van-field
            class="input"
            v-model="username"
            :placeholder="$t('输入邮箱')"
          />
        </div>
        <div class="captcha">
          <van-field v-model="captcha" :placeholder="$t('输入验证码')" />
          <van-button class="textBtn" plain type="info" @click="getcode()">
			<span v-show="show">{{tishi}}</span>
            <span v-show="!show">{{count}} s</span>
          </van-button>
        </div>
        <van-field
          class="input"
          v-model="password"
          type="password"
          :placeholder="$t('输入密码')"
        />
        <van-field
          class="input"
          v-model="password2"
          type="password"
          :placeholder="$t('再次输入密码')"
        />
      </van-form>
    </div>
<ROBOT />
    <div style="margin: 16px; padding: 32px 60px 0">
      <van-button class="btn" round block type="info" @click="subpasswd">{{
        $t('确认')
      }}</van-button>
    </div>
  </div>
  
</template>

<script>
	import { NavBar, Button, Field,Toast } from "vant";
	import ROBOT from '@/components/robot'
	import { apipaypassword,apiUser_mas2,apipaysms,apiRegss,apiForget} from "@/http/api/";
export default {
  name: 'welcome',
	components: {
	 
	  ROBOT
	 
	},
  data() {
    return {
       value: '+90',
	  option1: [
	  { text: "Turkey +90", value: "+90" },
	 
	    ],
      username: '',
      password: '',
      password2: '',
      captcha: '',
      count:60,
      show: true,
      timer: null,
      tishi: this.$t("获取验证码") ,
    }
  },

  methods: {
    captchaEvent() {
      this.textContent = '12miao'
    },
    getBack() {
      this.$router.back()
    },
    subpasswd(){
    		if(this.password != this.password2){
    			Toast.fail(this.$t('两次密码不一致'));
    			return;
    		}
    		if(!this.captcha){
    			Toast.fail(this.$t('请输入验证码'));
    			return;
    		}
    		apiForget({ 
    		  passwd:this.password2,	  
    		  code:this.captcha,
			  username:this.username,
    		  type:"reset"
    		}).then((res) => {
    		 if(res.status != 0){
    			Toast.success(this.$t(res.info));
    			this.$router.replace("/my");
    		 }else{
				 Toast.fail(this.$t(res.info)); 
    		 }
    		});
    	},
	getCode2(){
	  	const TIME_COUNT = 60;
			 if (!this.timer) {
			   this.count = TIME_COUNT;
			   this.show = false;
			   this.timer = setInterval(() => {
			   if (this.count > 0 && this.count <= TIME_COUNT) {
				 this.count--;
				} else {
				 this.show = true;
				 clearInterval(this.timer);
				 this.timer = null;
				}
			   }, 1000)
			  }
	}  ,
	getcode() {
		  	    if (!this.username) {
		  	      Toast.fail(this.$t("邮箱不能为空"));
		  	      return;
		  	    }
		  	    	
		  	    apiRegss({
		  	      username: this.username,
		  		 
		  		  optionval:this.optionval,
				  
		  	    }).then((res) => {
		  	      console.log(res);
		  	      if (res.status) {
		  			  
		  	        Toast.success(this.$t(res.info));
		  			  
		  			 this.getCode2();
		  	      } else {
		  	        Toast.fail(this.$t(res.info));
		  	      }
		  	    });
		  	  },
  }
}
</script>

<style lang="scss" scoped>
.page {
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
.van-field {
		background-color: #2B2B2B !important;
	}
	.van-field__control{
		background-color: #2B2B2B !important;
	}
	.van-dropdown-menu .van-dropdown-menu__bar{
		background-color: #2B2B2B !important;
	}
  .btn {
    box-shadow: 0px 10px 20px #0d9135;
    font-size: 40px;
    height: 103px;
    background: linear-gradient(1deg,#0d9135,#ffedd8);
    border: none;
    color: #000;
  }
  .phone {
    display: flex;
  }
  .captcha {
    height: 90px;
    padding: 10px;
    margin-bottom: 30px;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
    border-radius: 20px;
    .textBtn {
      width: 400px !important;
    }

    ::v-deep .van-button--plain.van-button--info {
      color: #0d9135;
    }
  }
  .content {
    margin-top: 90px;
    padding: 0 36px;
    .prefix {
      width: 180px;
      margin-right: 30px;
      ::v-deep input.van-field__control {
        color: #0d9135;
      }
    }
  }
  ::v-deep .van-button--plain {
    background-color: #2B2B2B;
  }
  ::v-deep.van-button--info {
    border: none;
  }
}
</style>
