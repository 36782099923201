export default {
  tabbar: {
    首页: 'Casa',
    市场: 'Mercado',
    任务: 'Trabalho',
    团队: 'Equipe',
    我的: 'Minha'
  },
  navbar: {
    充值: 'Depósito',
    提现: 'Retirada',
    帮助: 'Ajuda',
    分享: 'Compartilhar',
    活动: 'Eventos'
  },	 
  payment: {
    付款方式详情: 'Detalhes da forma de pagamento',
    银行卡: 'Cartão do banco',
    钱包地址: 'Endereço da carteira',
    交易密码: 'Senha da transação',
    保存: 'Salvar'
  },
  market: {
    市场: 'Mercado',
    名称: 'Nome',
    价格: 'Preço',
  },
 
  task: {
    任务: 'missão',
	语言切换: 'Troca de idioma',
    我的余额: 'Meu saldo',
    增加订单数量: 'Aumente a quantidade do pedido',
    今日剩余订单: 'Pedidos restantes hoje',
    频率: 'frequência',
    手动交易: 'Negociação manual',
    预期收益: 'Receita esperada',
    需要启动存款: 'Precisa iniciar o depósito',
    任务说明: 'Descrição da tarefa',
    每天可以完成10个任务: 'Pode completar 10 tarefas por dia',
    联系VIP助手增加任务数量: 'Contact the VIP assistant to increase the number of tasks',
    每个用户的任务总数为300次: 'The total number of tasks per user is 300',
    购买机器人无需人工操作即可自动交易: 'Transação automática sem operação manual ao comprar robôs',
    我知道了: 'Eu entendi!'
  },
  团队: 'Equipe',
  规则: 'Regra',
  会员级别: 'Nível de membro',
  经验: 'Experiência',
  代理信息: 'Informações do agente',
  更多: 'Mais detalhes',
  团队奖励: 'Prêmios da equipe',
  昨天: 'Ontem',
  本星期: 'Essa semana',
  全部: 'Tudo',
  团队收入记录: 'Registros de receita da equipe',
  机器人回扣: 'Renda do robô',
  会员体验: 'Experiência do membro',
  个人收入记录: 'Registros de renda pessoal',
  机器人采购: 'Compra de robôs',
  机器人启动押金: 'Depósito inicial do robô',
  机器人礼物: 'Presente de robô',
  机器人订单: 'Ordem do robô',
  机器人: 'Robô',
  一次性总和: 'Montante fixo',
  总计: 'Total',
  我的机器人: 'Meu robô',
  被激活: 'Ativado',
  工作中: 'Trabalhando',
  暂停: 'Pare',
  结束: 'Terminar',
  刷新: 'Atualizar',
  来源: 'Fonte',
  价格: 'Preço',
  启动押金: 'Preço inicial',
  剩余时间: 'Tempo restante',
  激活时间: 'Tempo de ativação',
  号: 'No.',
  我的资产: 'Meus ativos',
  退出: 'Fazer logout',
  邀请码: 'Código',
  我的钱包余额: 'Saldo da minha carteira',
  我的机器人仓库: 'Meu armazém robô',
  代理信息: 'Informações do agente',
  去提款: 'Retirada',
  昨天代理返利: 'Renda da agência ontem',
  累计代理返利: 'Renda acumulada da agência',
  今天机器人收益: 'Lucro do robô hoje',
  累计机器人收益: 'Renda acumulada',
  累计机器人回扣: 'Renda acumulada',
  累计总回报: 'Retorno total acumulado',
  自动订单机器人数量: 'Número de robôs de pedidos automáticos',
  我的付款方式: 'Minha forma de pagamento',
  个人收入记录: 'Registros de renda pessoal',
  团队收入记录: 'Registros de receita da equipe',
  充值记录: 'Registro de recarga',
  我的团队成员: 'Membros da minha equipe',
  活动中心: 'Centro de eventos',
  修改登录密码: 'Modificar senha de login',
  交易密码管理: 'Gerenciamento de senhas de transações',
  机器人: 'Robô',
  机器人性能: 'Desempenho do robô',
  机器人价格: 'Preço do robô',
 
  有效期: 'Período de validade',
  
  天: 'Dias',
  去购买: 'Para comprar',
  买个机器人: 'Comprar robô',
 
  预计日收益: 'Renda diária estimada',
  启动保证金: 'Depósito de arranque',
  请输入购买数量: 'Por favor, insira a quantidade de compra',
  个机器人: ' PCs. / robô',
  机器人购买后需要激活才能工作: 'O robô precisa ser ativado para funcionar após a compra',
  机器人激活需要从余额中扣除相应的启动押金:
    'A ativação do robô precisa deduzir o depósito inicial correspondente do saldo',
  机器人暂停机器人停止时将返还启动押金:
    'Quando o robô parar, o depósito inicial será devolvido',
	不激活的机器人可以赠送:"Robôs inativos podem ser doados",
  购买: 'Comprar',
  点击去了解我们: 'Clique para saber sobre nós',
  购买机器人: 'Comprar robô',
  快速入口: 'Entrada rápida',
  '日/个人受益': 'Diária / benefício pessoal',
  购买教程: 'Tutoriais de compra',
  订单数量: 'Quantidade do pedido',
  我们的合作伙伴: 'Nossos parceiros',
  提款: 'Retirada',
  提款金额: 'Quantidade retirada ',
  你还没有添加银行卡: 'Você não adicionou um cartão bancário',
  提现说明: 'Descrição da retirada',
  确认提款: 'Confirmar retirada ',
  钱包余额: 'Saldo da carteira',
  输入数量:"Insira o valor USDT",
  数量:"Quantia",
  登录: 'Conecte-se',
  输入账号: 'Digite o número da conta',
  输入密码: 'Insira a senha',
  还没有账号点击注册: 'Sem conta? Clique em Cadastrar',
  忘记密码: 'Esqueceu a senha',
  重新登录: 'Faça login novamente',
  密码找回成功: 'Senha recuperada com sucesso',
  找回成功请重新登录: 'Recuperado com sucesso, faça login novamente',
  确认: 'Confirmar',
  发送验证码: 'Enviar código de verificação',
  再次输入密码: 'Digite a senha novamente',
  输入验证码: 'Digite o código de verificação',
  输入手机号: 'Digite o número do celular',
  注册: 'Registro',
  输入用户名: 'Digite um nome de usuário',
  请输入邀请码: 'Por favor, insira o código do convite',
  返回: 'Retornar',
  密码找回失败: 'Falha na recuperação da senha',
  输入的信息有误: 'Informações incorretas inseridas',
	
	获取验证码:"Obter código",
	手机号码不能为空:"O número do celular não pode ficar vazio",
	账号不存在:"A conta não existe",
	发送成功:"Enviado com sucesso",
	今日收益:"Ganhos de hoje",
	
	日收益:"Renda diária",
	
	数量不能为空:"A quantidade não pode estar vazia",
	加载中:"Carregando...",
	唯一码:"Código único",
	未激活:"Não ativo",
	激活:"Ativação",
	购买时间:"Tempo de compra",
	"钱包余额不足，激活该机器人失败":"Saldo de carteira insuficiente, falha ao ativar o robô",
	暂无数据:"Sem dados...",
	激活时间:"Tempo de ativação",
	运行时长:"Tempo de execução",
	签约购买:"Contrato de compra",
	系统赠送:"Presente do sistema",
	状态:"Estado",
	正常:"Normal",
	我的机器人:"Meu robô",
	一级会员:"Nível 1",
	二级会员:"Nível 2",
	三级会员:"Nível 3",
	人:"Pessoas",
	充值客服:"Recarregar atendimento ao cliente",
	充值:"Recarrega",
	提现:"Retirada",
	设置交易密码:"Definir senha da transação",
	登录密码:"Senha de login",
	请输入交易密码:"Por favor, digite a senha da transação",
	再次请输入交易密码:"Por favor, digite sua senha de transação novamente",
	确认: 'Confirmar',
	手机号码不能为空:"O número do celular não pode ficar vazio",
	两次密码不一致:"As duas senhas são inconsistentes",
	请输入验证码:"Por favor, insira o código de verificação",
	操作成功:"A operação é bem sucedida",
	"用户名或密码不正确,登录失败":"Nome de usuário ou senha incorretos, falha no login",
	登录成功:"Login bem-sucedido",
	充值说明:"Instruções de recarga",
	请先设置支付密码:"Por favor, defina a senha de pagamento primeiro",
	复制成功:"Cópia bem-sucedida",
	冻结机器人做单本金:"Congelando o principal dos pedidos do robô",
	待审核:"A ser revisado",
	成功:"Sucesso",
	失败:"Falhou",
	审核中:"Sob revisão",
	在线充值:"Recarga online",
	描叙:"Descreva",
	银行卡提现:"Retirada do cartão bancário",
	USDT提现:"Retirada de USDT",
	三级代理:"Agente de terceiro nível",
	一级代理:"Agente principal",
	二级代理:"Agente secundário",
	做单:"Fazer um pedido",
	团队返佣: "Renda da equipe",
	购买机器人返佣:"Compre renda de robô",
	本金返还:"Retorno do principal",
	时间:"Tempo",
	机器人做单返还:"Robô faz pedido e devolve",
	涨幅:"Aumentar",
	
  付款方式:"Método de pagamento",
	我的留言:"Minha mensagem",
  
  银行卡:'Cartão bancário',
  修改: 'Modificar',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'Verifique se o endereço da carteira está correto, para evitar a perda causada por usuários que não podem receber dinheiro devido à entrada incorreta do endereço',
 连接借记卡:"Conectar cartão de débito",
 银行名称:'Nome do banco',
 姓名姓氏: 'Nome',
 IBAN号码:'Nº do cartão bancário',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* Importante: as informações do cartão bancário devem ser verdadeiras e válidas antes da retirada.',
 帮助中心:'Centro de ajuda',
 会员须知:'Notas aos membros',
 '24小时内使用USDT实时取款（推荐）':'1. Real time withdrawal with usdt within 24 hours (recommended)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. bank card withdrawal * withdrawal time is 10:00-18:00 * account entry within 2 hours after the withdrawal is initiated * application for non withdrawal time or inter-bank withdrawal will be received at 10:00-18:00 the next day',
 我知道了:'Eu entendi!',
 链接点击:'Clique no link',
 
 建立自己的团队邀请:'Crie seu próprio convite de equipe',

 加入:'Junte',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "O robô que você vendeu foi aceito pela outra parte. O produto da venda foi pago em sua conta. por favor, verifique.",
 	"你出售的机器人对方已拒绝接受":"O robô que você vendeu foi rejeitado pela outra parte",
 	向你赠送一台机器人:"Um robô é apresentado a você para confirmação",
 	向你出售一台机器人:"Vender-lhe um robô, a ser confirmado",
 	出售价格不能超过机器人价格:"O preço de venda não pode exceder o preço do robô",
 	银行卡已被绑定:"O cartão do banco foi vinculado",
 	USDT_TRC20提现:'Saque USDT_TRC20',
 	USDT_ERC20提现:'Saque USDT ERC20',
 	银行卡提现:'Retirada do cartão bancário',
 	邀请赠送:'Presente de convite',
 	机器人返佣:'Comissão de Robôs',
 	升级:'atualizar',
 	充值主网:'Recharge main network',
   "您被限制出售机器人,请联系客服了解详情":
     "Você está impedido de vender robôs. Por favor, contacte o serviço ao cliente para ter detalhes",
   交易编号: "Nº da transação",
   消息详情:"Detalhes da mensagem",
   个人收益累计:"Renda pessoal acumulada",
   今日代理收益:"Renda da agência hoje",
   代理累计收益:"Renda acumulada da agência",
  
   截图保存推荐给朋友:"Salve capturas de tela e recomende a amigos",
   复制:"Cópia",
   
   请输入正确的充值金额:"Insira o valor de recarga correto",
   推荐:"recomendar",
   充值金额:"Valor da recarga",
   请上传付款截图:"Faça o upload da captura de tela de pagamento",
   充值ID提示:"Número de TXID",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Please ensure that the wallet address is correct. If the wallet address is filled in incorrectly, the user will bear the losses caused by the inability to collect money.",
	上传凭证:"Carregar comprovante",
	不能为空:"Não pode estar vazio",
  绑定钱包地址:'Endereço da carteira de vinculação',
	
	备注:"Observações",
	请输入你的名字:"Por favor, insira seu nome",
	请输入银行卡号:"Insira o número do cartão bancário",
	银行卡号:"Nº do cartão bancário",
	添加银行卡:"Adicionar cartão bancário",
	请选择银行:"Selecione um banco",
	请输入钱包地址:"Digite o endereço da carteira",
	钱包地址:"Endereço da carteira",
	"取款须知":"Instruções para retirada",
	"须知1":"1. Real time withdrawal with usdt within 24 hours (recommended)",
	"须知2":"2.bank card",
	"须知3":"Withdrawal * withdrawal time is 10:00-18:00",
	"须知4":"*The application for non withdrawal time or inter-bank withdrawal will be received at 10:00-18:00 the next day",
	
	已绑定:"Vinculado",
	去设置:"Configuração",
	汇率:"taxa de câmbio",
	请输入正确的提现金额:"Por favor, insira o valor de retirada correto",
	提款记录:"Registro de saque",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"dica1: o endereço da carteira vinculada deve ser consistente com a carteira de pagamento, caso contrário não será recebido。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"dica2: Espera-se que chegue em cerca de um minuto após a recarga bem-sucedida. Por favor, verifique sua carteira.",
	请先绑定钱包地址:"Por favor, vincule o endereço da carteira primeiro",
	输入邮箱:"Digite Gmail",
	指定用户不存在:"usuário especificado não existe",
	今日涨幅:"Aumentar",
	快捷充币:"Depósito Rápido",
	快速买USDT:"Compra rápida USDT",
	在线支付:"Pagamento online",
	自动到账:"Recibo automático",
	线下支付:"Pagamento off-line",
	联系客服获取验证码:"Entre em contato com o atendimento ao cliente para obter o código de verificação",
	获取:"Obter",
	重新登录:"Faça login novamente",
	退出成功:"Saída bem-sucedida",
	不能低于最小充值:"Não pode ser inferior à recarga mínima",
	不能低于最小提现金额:"Não inferior ao valor mínimo de retirada",
	最小提现:"Retirada mínima",
	官方充币:"Cobrança oficial",
	银行卡充值:"Recarga de cartão bancário",
	等待审核:"Revisão pendente",
	可提现金额不足:"Quantidade de saque insuficiente",
	未经授权:"não acreditado",
	交易密码不正确:"Senha de transação incorreta",
	提现次数不足:"Tempos de retirada insuficientes",
	需要更新的银行卡不存在:"O cartão bancário a ser atualizado não existe",
	银行卡提现:"Retirada do cartão bancário",
	激活金额:"Quantidade de ativação",
	'赠送/出售':"Dar / Venda",
	赠送:"Dar",
	出售:"Venda",
	账户usdt不足:"Falta de equilíbrio",
	请输入提现金额:"Por favor, insira o valor da retirada",
	恭喜新用户:"Parabéns aos novos usuários ",
	kefu1:"Vídeo tutorial do Youtube",
	登录密码:"Senha de login",
	再次请输入交易密码:"Digite a senha da transação novamente",
	输入手机号码:"Número de telefone",
	输入手机号码:"Digite o número de telefone",
	输入密码:"Senha",
	请输入密码:"Digite a senha",
	手机号格式错误:"Erro de formato de número de celular",
	"密码错误次数过多,请等待x秒后再试":"Muitos erros de senha, aguarde 600 segundos e tente novamente",
	"此账号已冻结,请联系客服":"Esta conta foi congelada, entre em contato com o atendimento ao cliente",
	登录失败:"Falha no início de sessão",
	请勿重复操作:"Não repita",
	邀请码不正确:"Código de convite incorreto",
	此账号已冻结:"Esta conta foi congelada",
	请输入手机号:"Por favor, insira seu número de telefone",
	验证码错误:"Erro no código de verificação",
	手机号存已存在:"O número de telefone celular já existe",
	注册账号已存在:"A conta registrada já existe",
	请确定新密码:"Por favor, confirme a nova senha",
	请再次输入登录密码:"Por favor, digite sua senha de login novamente",
	密码长度不能少于6位:"O comprimento da senha não pode ser inferior a 6 dígitos",
	
	输入邮箱:"Digite Gmail",
	注册邮箱已存在:"Registro Gmail já existe",
	请输入用户ID:"Por favor, insira o ID do usuário",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"Por favor, revise cuidadosamente as informações se você inserir o nome da conta da outra parte e transferir a propriedade. Se houver um erro de operação, ele não poderá ser recuperado pelo sistema ou pelo atendimento ao cliente da plataforma.",
	请输入出售金额:"Insira o valor da venda",
	出售中:"O presente foi enviado, aguardando confirmação",
	 收到一台系统赠送的机器人 :"Recebeu um robô do sistema",
	 会员出售:"Membros vendem",
	 "出售待确认中,请勿重复操作":"Venda pendente de confirmação, não repita a operação",
	 激活成功:"Ative o sucesso",
	 操作成功:"A operação é bem sucedida",
	
	输入邮箱:"Digite Gmail",
	
	请输入登录密码:"Por favor, digite sua senha de acesso",
	未满足条件:"Condição não atendida",
	活动已过期:"Atividade expirou",
	"邀请好友注册，领取佣金":"Convide amigos para se cadastrar e receber comissão",
	领取:"Receber",
	团队规则:"Regra da equipe",
	邮箱已存在:"O Gmail já existe",
	获取邮箱验证码:"Obter código",
	请输入邮箱:"Por favor, digite o endereço de e-mail",
	手机号码:"Telefone",
	邮箱不能为空:"O e-mail não pode ficar vazio",
	"出售/赠送":"Venda / Presente",
	已接受:"Aceitaram",
	"出售/赠送人":"Vendedor / doador",
	"向你赠送一台机器人，待确认":"Um robô é apresentado a você para confirmação",
	国码:"Código do país",
	取消:"Cancelar",
	银行卡号位数错误:"Número incorreto de cartões bancários",
	官网:"Official site",
	银行:"Banco",
	注册成功:"Login bem-sucedido",
	设置成功:"Definido com sucesso",
	手机号码已存在:"O número de celular já existe",
	修改成功:"Modificado com sucesso",
	立即登录:"Já tem uma conta, clique para entrar",
	客服:"Conversar",
	邮箱:"O email",
	机器人量化AI交易收益:"Robô quantifica receita de negociação de IA",
	体验机器人不能赠送:"Robôs de experiência não podem ser doados",
	接受:"Aceite",
	拒绝:"Recusar",
	语言切换:"Troca de idioma",
}
